import ContactUsForm from "./forms/ContactUsForm";
import Fade from "react-reveal/Fade";
import { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PlayButton from "./PlayButton";
import VideoModal from "./VideoModal";
import bestbuy from "../assets/img/clients/client-best.svg";
import devices from "../assets/img/home/alluxi-devices-cropped.svg";
import globeo from "../assets/img/clients/client-globeo.svg";
import megacable from "../assets/img/clients/client-megacable.svg";
import simpat from "../assets/img/clients/client-simpat.svg";
import skydropx from "../assets/img/clients/client-skydropx.svg";
import { useTranslation } from "react-i18next";

export default function MainHero({ noClick, scrollRef, landing }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const scrollBottom = (e) => {
    e.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t("home")} | Alluxi </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("we-build-technology")} />
        <meta property="og:title" content={t("home")} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("we-build-technology")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <VideoModal isOpen={isModalOpen} onClose={closeModal} />

      <div className={`bg-agray-100 ${noClick ? "" : "pt-16 md:pt-28"}`}>
        <main className={`px-6 md:px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto ${noClick ? "pt-16 md:pt-28" : ""}`}>
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="flex items-center col-span-1 w-full order-2">
              {true ? (
                <div className="relative w-[278px] mt-[32px] mb-[26px] lg:w-[400px] md:mt-0 md:mb-0 m-auto floating-effect">
                  <Fade bottom>
                    <img src={devices} alt="devices" className="w-full h-auto" />
                  </Fade>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <PlayButton
                      onClick={openModal}
                      aria-label="Play Video"
                      className="w-12 h-12 md:w-16 md:h-16 transition-colors duration-300 ease-in-out hover:text-ablue-500 text-white"
                    ></PlayButton>
                  </div>
                </div>
              ) : (
                <ContactUsForm />
              )}
            </div>
            <div className="col-span-1 xl:pt-8 md:pt-8 text-left w-full">
              <div>
                <div className="pb-4">
                  {/* <span className="text-sm tracking-[0.15em] font-semibold text-ablue-600 font-bold ">
                    {t("s-p-s")}
                  </span> */}
                </div>
                <h1 className="text-3xl md:text-4xl xl:text-5xl text-nblue-900 font-bold">
                  {t("digital-products")}
                </h1>
                <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                  {t("we-build-technology")}
                </p>
                <div className="mt-8 md:flex md:items-center">
                  <div className="mb-4">
                    <Link
                      to={
                        noClick
                          ? ""
                          : currentLanguage === "en"
                          ? "/contact"
                          : "/es/contact"
                      }
                      onClick={noClick ? () => scrollBottom(scrollRef) : ""}
                      className="bg-gradient-to-r bg-animate-rg from-ablue-500 to-ablue-900 hover:from-agray-500 hover:to-agray-500 w-40 md:w-52 flex items-center justify-center px-4 py-3 md:px-[12px] md:py-[12px] text-sm font-manrope rounded-[28px] text-white"
                    >
                      <label>{t("next-project")}</label>
                    </Link>
                  </div>
                  {i18n.language === "en-EN" || i18n.language === "en" ? (
                    <ul className="max-w-md space-y-1 text-gray-500 font-light list-inside dark:text-agray-500 md:ml-8">
                      <li className="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Access our network of technical experts 
                      </li>
                      <li className="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Cut costs and increase productivity going nearshore
                      </li>
                      <li className="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        30-day risk free trial
                      </li>
                      <li className="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Enjoy seamless communication and collaboration with our English-fluent team
                      </li>
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <p className="text-agray-200 self-center mt-8">
            {t("trusted-by")}
          </p>

          {i18n.language === "en-EN" || i18n.language === "en" ? (
            <div className="mt-0 grid grid-cols-3 gap-4 md:grid-cols-6 xl:grid-cols-6 py-6">
              <p></p>
              <div className="col-span-2 md:col-span-5"></div>
              <Fragment>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-5/6">
                  <img className="w-1/2" src={bestbuy} alt="Best Buy" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-5/6">
                  <img
                    src={require("../assets/img/clients/colored-client-headspring.png")}
                    alt="Headspring"
                  />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-5/6">
                  <img src={globeo} alt="Globeo" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-5/6">
                  <img
                    src={require("../assets/img/clients/colored-client-market.png")}
                    alt="World Market Cost Plus"
                  />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-5/6">
                  <img src={simpat} alt="Simpat" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-5/6">
                  <img src={megacable} alt="Megacable" />
                </div>
              </Fragment>
            </div>
          ) : (
            <div className="mt-2 grid grid-cols-3 md:grid-cols-5 xl:grid-cols-5 py-6">
              <Fragment>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-4/6 opacity-90">
                  <img src={megacable} alt="Megacable" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-4/6 opacity-90">
                  <img
                    src={require("../assets/img/clients/client-truehome.png")}
                    alt="Truehome"
                  />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-4/6 opacity-90">
                  <img src={skydropx} alt="Skydropx" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-4/6 opacity-90">
                  <img
                    src={require("../assets/img/clients/client-vector.png")}
                    alt="Vector"
                  />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 xl:col-span-1 w-4/6 opacity-90">
                  <img
                    className="m-auto"
                    src={require("../assets/img/clients/client-educaruno.png")}
                    alt="Educaruno"
                  />
                </div>
              </Fragment>
            </div>
          )}
        </main>
      </div>
    </Fragment>
  );
}
