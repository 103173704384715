import React, { useState } from 'react';

const PlayButton = ({ onClick }) => {
  const [setIsHovered] = useState(false);

  return (
    <div className='flex items-center self-center mr-auto ml-auto'>
      <button
        className="relative w-20 h-20 rounded-full bg-red-500 hover:scale-110 transition-transform duration-300 animate-pulse hover:animate-none hover:bg-ablue-500"
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}

        onClick={onClick}
      >
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-ablue-500 text-4xl hover:text-white hover:scale-110 transition-transform duration-300 animate-pulse hover:animate-none ">
          ▶
        </span>
      </button>
    </div>
  );
};

export default PlayButton;