import './App.css';

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import AboutUs from './components/AboutUs';
import Blog from './components/blog/Blog';
import BlogDetail from './components/blog/BlogDetail';
import Careers from './components/Careers';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './Home.js';
import Industry from './components/Industry';
import Landing from './Landing';
import Navbar from './components/Navbar';
import NotFound404 from './components/NotFound404';
import OurWork from './components/OurWork';
import OurWorkDetail from './components/OurWorkDetail';
import PrivacyPolicy from './components/PrivacyPolicy';
import ServiceUXHero from './components/ServicesUXHero';
import ServicesAIHero from './components/ServicesAIHero';
import ServicesAnalyticsHero from './components/ServicesAnalyticsHero';
import ServicesCloudHero from './components/ServicesCloudHero';
import ServicesMobileHero from './components/ServicesMobileHero';
import ServicesNearShore from './components/ServicesNearShore';
import ServicesWebHero from './components/ServicesWebHero';
import Technology from './components/Technology';
import Thankyou from './components/Thankyou';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// New layout component without Navbar and Footer
function LayoutWithoutNavbarFooter() {
  return <Outlet />;
}

function LayoutsWithNavbar() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get('q');
  const navbar = qParam !== 'landing' ? <Navbar /> : <Navbar />;

  return (
    <>
      {navbar}
      <Outlet />
      <Footer />
    </>
  );
}

function ScrollTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}
function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = i18n.language;

    const existingScript = document.getElementById('intercomSettingsScript');
    if (existingScript) {
      document.head.removeChild(existingScript);
    }

    const script = document.createElement('script');
    script.id = 'intercomSettingsScript';
    script.innerHTML = `
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "uoyyi21t",
        language_override: "${language}"
      };
    `;

    document.head.appendChild(script);
  }, [i18n.language]);

  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route>
            <Route path="landing" element={<Landing language="en" />} />
            <Route path="es/landing" element={<Landing language="es" />} />
          </Route>
          <Route path="/" element={<LayoutsWithNavbar />}>
            <Route path="es" element={<Home language="es" />} />
            <Route path="en" element={<Navigate to="/" replace />} />
            <Route path="/" element={<Home language="en" />} />

            <Route
              path="services/custom-mobile-development"
              element={<ServicesMobileHero language="en" />}
            />
            <Route
              path="es/services/custom-mobile-development"
              element={<ServicesMobileHero language="es" />}
            />
            <Route
              path="services/custom-web-development"
              element={<ServicesWebHero language="en" />}
            />
            <Route
              path="es/services/custom-web-development"
              element={<ServicesWebHero language="es" />}
            />
            <Route
              path="services/nearshore-development"
              element={<ServicesNearShore language="en" />}
            />
            <Route
              path="es/services/nearshore-development"
              element={<ServicesNearShore language="es" />}
            />
            <Route
              path="services/cloud-services"
              element={<ServicesCloudHero language="en" />}
            />
            <Route
              path="es/services/cloud-services"
              element={<ServicesCloudHero language="es" />}
            />
            <Route
              path="services/ai-integration-services"
              element={<ServicesAIHero language="en" />}
            />
            <Route
              path="es/services/ai-integration-services"
              element={<ServicesAIHero language="es" />}
            />
            <Route
              path="services/ux-ui-services"
              element={<ServiceUXHero language="en" />}
            />
            <Route
              path="es/services/ux-ui-services"
              element={<ServiceUXHero language="es" />}
            />
            <Route
              path="services/analytics-services"
              element={<ServicesAnalyticsHero language="en" />}
            />
            <Route
              path="es/services/analytics-services"
              element={<ServicesAnalyticsHero language="es" />}
            />
            <Route path="our-work" element={<OurWork language="en" />} />
            <Route path="es/our-work" element={<OurWork language="es" />} />
            <Route
              path="our-work/detail/:slug"
              element={<OurWorkDetail language="en" />}
            />
            <Route
              path="es/our-work/detail/:slug"
              element={<OurWorkDetail language="es" />}
            />
            <Route
              path="technology/:slug"
              element={<Technology language="en" />}
            />
            <Route
              path="es/technology/:slug"
              element={<Technology language="es" />}
            />
            <Route path="industry/:slug" element={<Industry language="en" />} />
            <Route
              path="es/industry/:slug"
              element={<Industry language="es" />}
            />
            <Route path="about-us" element={<AboutUs language="en" />} />
            <Route path="es/about-us" element={<AboutUs language="es" />} />
            <Route path="careers" element={<Careers language="en" />} />
            <Route path="es/careers" element={<Careers language="es" />} />
            <Route path="blog" exact element={<Blog language="en" />} />
            <Route path="es/blog" exact element={<Blog language="es" />} />
            <Route path="blog/detail/:slug" element={<BlogDetail />} />

            <Route
              path="privacy-policy"
              element={<PrivacyPolicy language="en" />}
            />
            <Route
              path="es/privacy-policy"
              element={<PrivacyPolicy language="es" />}
            />
            <Route path="/thankyou" element={<Thankyou language="en" />} />
            <Route path="/es/thankyou" element={<Thankyou language="es" />} />
            <Route path="*" element={<NotFound404 />} />
            <Route path="/page-not-found" component={NotFound404} />
          </Route>

          {/* Add new route for contact pages without Navbar and Footer */}
          <Route element={<LayoutWithoutNavbarFooter />}>
            <Route path="contact" element={<Contact language="en" />} />
            <Route path="es/contact" element={<Contact language="es" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
