import { Fragment, useCallback, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BlogSuggestions({ vertical = false }) {
  let { slug } = useParams();
  const [relatedBlog, setRelatedBlog] = useState([]);
  const { i18n } = useTranslation();

  // Fetch blog data based on the tag
  const fetchBlogData = useCallback(
    async (tag) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}?key=${process.env.REACT_APP_KEY}&filter=tag:${tag}`
        );
        const data = await response.json();
        const transformedBlog = data.posts.map((blogData) => ({
          id: blogData.uuid,
          slug: blogData.slug,
          title: blogData.title,
          description: blogData.excerpt.replace(/\n/g, ""),
          image: blogData.feature_image,
        }));

        const results = transformedBlog.filter((element) => element.slug !== slug);
        setRelatedBlog((prev) => [...prev, ...results]);
      } catch (error) {
        console.error("Something went wrong", error);
      }
    },
    [slug]
  );

  // Fetch blog data based on the current language
  useEffect(() => {
    const tag = i18n.language === "es-ES" || i18n.language === "es" ? "es" : "en";
    fetchBlogData(tag);
  }, [i18n.language, fetchBlogData]);

  // Get the first 4 related blog items
  const items = [...new Map(relatedBlog.map((item) => [item.id, item])).values()].slice(0, 3);

  return (
    <Fragment>
      <div className={`${vertical ? 'mt-4 space-y-4 bg-white z-102' : 'md:grid md:grid-cols-3 md:gap-6 lg:gap-12 mb-4'}`}>
        {items.length > 0 ? (
          items.map((blog, index) => (
            <BlogSuggestionItem key={index} blog={blog} vertical={vertical} />
          ))
        ) : (
          <p className="font-light text-xl text-agray-500 text-center">No posts found</p>
        )}
      </div>
      {!vertical && (
        <div className="md:hidden w-full flex justify-center">
        </div>
      )}
    </Fragment>
  );
}

// Component to render each blog suggestion item
const BlogSuggestionItem = ({ blog, vertical }) => (
  <Link to={`/blog/detail/${blog.slug}`} className="block group">
    <div className={`
      ${vertical ? 'flex items-center w-full' : 'flex flex-col'}
      mb-4 ${vertical ? 'space-x-4' : 'space-y-4'}
      transition duration-300 ease-in-out transform hover:scale-102
      hover:shadow-sm rounded-xl p-3 hover:bg-gray-50
    `}>
      <div className={`${vertical ? 'w-20 h-20' : 'w-full h-52'} overflow-hidden rounded-xl`}>
        <img
          src={blog.image ?? require("../../assets/img/blog-default.png")}
          className={`
            w-full h-full object-cover rounded-xl
            transition duration-300 ease-in-out transform group-hover:scale-105
          `}
          alt="related-post"
        />
      </div>
      <div className={`${vertical ? 'flex-1' : ''}`}>
        <h3 className="text-base font-bold text-nblue-900 line-clamp-2 mb-2 group-hover:text-ablue-600 transition duration-300">
          {blog.title}
        </h3>
        {!vertical && (
          <p className="mt-2 text-sm text-agray-500 line-clamp-2 mb-4 group-hover:text-agray-700 transition duration-300">
            {blog.description}
          </p>
        )}
      </div>
    </div>
  </Link>
);
