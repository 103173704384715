import 'swiper/css';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

import ContactUs from './ContactUs';
import FAQs from './FAQs';
import { Helmet } from 'react-helmet';
import Ready from './Ready';
import WeHaveItAll from './WeHaveItAll';
import WhyChooseUs from './WhyChooseUs';
import { useCasesData } from '../useCasesData';
import { useTranslation } from 'react-i18next';

export default function ServicesMobileHero(props) {
  const [swiper, setSwiper] = useState({});
  SwiperCore.use([Navigation, Autoplay]);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const navigate = useNavigate();

  const case_studies = useCasesData();
  const [index, setIndex] = useState(0);

  const [loading, setLoading] = useState(true);

  const [studies] = useState(case_studies);
  const [cases, setCases] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get('q');

  useEffect(() => {
    const filteredArr = studies.filter((item) => item.type === 'mobile');

    setCases(filteredArr);
    setLoading(false);
  }, [studies]);

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <Fragment>
      <Helmet>
        <title>{t('mobile-service')} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta property="description" content={t('mobile-desc')} />
        <meta property="og:title" content={t('mobile-service')} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t('mobile-desc')} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={require('../assets/img/services/hero-mobile.png')}
        />
      </Helmet>
      <div className="bg-agray-100 pt-40">
        <main className={``}>
          <div className="px-4 pb-16 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto md:grid md:grid-cols-2 md:gap-6">
            <img
              className="order-2 my-auto"
              src={require('../assets/img/services/hero-mobile.png')}
              alt="gradient_circle"
            />
            <div className="text-left w-full">
              <div>
                <div className="pb-4">
                  <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                    {t('custom-mobile-develop')}
                  </span>
                </div>
                <h1 className="text-5xl text-nblue-900 font-bold">
                  {t('mobile-subtitle')}
                </h1>
                <p className="font-light mt-4  text-lg text-agray-500 w-10/12 ">
                  {t('mobile-desc')}
                </p>
                <div className="mt-9">
                  <Link
                    to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
                    className="hover:bg-nblue-600 bg-ablue-600 w-72 flex items-center justify-center px-[22px] py-[17px]  text-sm font-manrope rounded-[28px] text-white"
                  >
                    <label>{t('next-project')}</label>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="relative pt-0 md:pt-16 pt-16 overflow-hidden m-auto bg-white">
            <div className="pb-[112px] md:pb-16 mt-[120px] md:mt-24 px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto md:grid md:grid-cols-2 gap-[88px] lg:grid-flow-col-dense">
              <div className="order-2">
                <div className="mt-6">
                  <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                    {t('mobile-hero-title')}
                  </span>
                  <h2 className="text-5xl text-nblue-900 font-bold">
                    {t('mobile-hero-subtitle')}
                  </h2>
                  <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                    {t('mobile-hero-desc')}
                  </p>
                </div>
              </div>
              <img
                className="w-[358px] h-[404px] md:w-[489px] md:h-[552px]"
                src={require('../assets/img/services/mobiledesc.png')}
                alt="mobile_description"
              />
            </div>
            {!loading && (
              <Fragment>
                {/* SLIDER */}
                <div className="mt-16 pt-16">
                  <div className="px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-[128px]">
                    <div className="lg:col-start-1 ">
                      <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                        {t('our-work')}
                      </span>
                      <h2 className="text-5xl text-nblue-900 font-bold">
                        {cases[index].name}
                      </h2>
                      <p className="font-light mt-4 text-lg text-agray-500 m-auto line-clamp-3">
                        {t(cases[index].challenge)}
                      </p>
                      <div className="mt-9">
                        <button
                          className="justify-center w-64 h-14 px-[39px] py-[12px] border rounded-[28px] shadow-sm text-[14px] font-manrope text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow hover:from-agray-500 hover:to-agray-500 flex mt-16 items-center"
                          onClick={(e) =>
                            navigate(
                              currentLanguage === 'en'
                                ? `/our-work/detail/${cases[index].slug}`
                                : `/es/our-work/detail/${cases[index].slug}`
                            )
                          }
                        >
                          {t('see-more')}
                        </button>
                      </div>
                    </div>
                    <div className="lg:col-start-2 lg:w-[448px] lg:h-[512px] ">
                      <Swiper
                        className="  h-96 bg-green-300 rounded-3xl "
                        slidesPerView={1}
                        onInit={(ev) => {
                          setSwiper(ev);
                          setIndex(ev.activeIndex);
                        }}
                        modules={[Autoplay]}
                        autoplay={{ delay: 2000 }}
                        spaceBetween={50}
                        onSlideChange={(e) => setIndex(e.realIndex)}
                        onSwiper={(swiper) => setIndex(swiper.activeIndex)}
                      >
                        {cases.map((item, index) => (
                          <SwiperSlide
                            key={index}
                            onClick={(e) =>
                              navigate(
                                currentLanguage === 'en'
                                  ? `/our-work/detail/${cases[index].slug}`
                                  : `/es/our-work/detail/${cases[index].slug}`
                              )
                            }
                          >
                            <img
                              className="m-auto my-4 md:my-0"
                              src={item.image_cover}
                              alt="cover"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <div className="flex h-10 w-auto float-left mt-6 text-ablue-900">
                        <button
                          onClick={() => {
                            swiper.slidePrev();
                          }}
                          id="swiper-backward"
                        >
                          <ArrowLeftIcon className="mr-8 h-9 w-9 hover:text-sky-400"></ArrowLeftIcon>
                        </button>
                        <button
                          onClick={() => {
                            swiper.slideNext();
                          }}
                          id="swiper-forward"
                        >
                          <ArrowRightIcon className="h-9 w-9 hover:text-sky-400"></ArrowRightIcon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
          <div className="px-4 mb-16 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
            <div className="pt-16 text-center">
              <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t('tools')}
              </span>
              <h2 className="text-5xl text-nblue-900 font-bold">
                {t('our-teck-stack')}
              </h2>
              <p className="font-light mt-4  text-lg text-agray-500 w-4/5 xl:w-1/2 m-auto  ">
                {t('our-teck-desc')}
              </p>
            </div>
            <div className="py-10">
              <div className="mt-6 grid grid-cols-6  md:grid-cols-6 lg:grid-cols-6">
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <Link to={`${currentLanguage === 'en' ? '' : '/es'}/technology/vue-software-development`} className="group">
                    <div>
                      <img
                        className="w-36 my-auto transition-transform group-hover:scale-110"
                        src={require('../assets/img/services/tool-vue.png')}
                        alt="Vue.js"
                      />
                      <p className="text-sm text-agray-500 text-center group-hover:text-ablue-600">
                        Vue.js
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <Link to={`${currentLanguage === 'en' ? '' : '/es'}/technology/python-software-development`} className="group">
                    <div>
                      <img
                        className="w-36 my-auto transition-transform group-hover:scale-110"
                        src={require('../assets/img/services/tool-python.png')}
                        alt="Python"
                      />
                      <p className="text-sm text-agray-500 text-center group-hover:text-ablue-600">
                        Python
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <Link to={`${currentLanguage === 'en' ? '' : '/es'}/technology/react-software-development`} className="group">
                    <div>
                      <img
                        className="w-36 my-auto transition-transform group-hover:scale-110"
                        src={require('../assets/img/services/tool-react.png')}
                        alt="React"
                      />
                      <p className="text-sm text-agray-500 text-center group-hover:text-ablue-600">
                        React
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <Link to={`${currentLanguage === 'en' ? '' : '/es'}/technology/javascript-software-development`} className="group">
                    <div>
                      <img
                        className="w-36 my-auto transition-transform group-hover:scale-110"
                        src={require('../assets/img/services/tool-js.png')}
                        alt="Javascript"
                      />
                      <p className="text-sm text-agray-500 text-center group-hover:text-ablue-600">
                        Javascript
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <Link to={`${currentLanguage === 'en' ? '' : '/es'}/technology/ionic-software-development`} className="group">
                    <div>
                      <img
                        className="w-36 my-auto transition-transform group-hover:scale-110"
                        src={require('../assets/img/services/tool-ionic.png')}
                        alt="Ionic"
                      />
                      <p className="text-sm text-agray-500 text-center group-hover:text-ablue-600">
                        Ionic
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <Link to={`${currentLanguage === 'en' ? '' : '/es'}/technology/postgresql-software-development`} className="group">
                    <div>
                      <img
                        className="w-36 my-auto transition-transform group-hover:scale-110"
                        src={require('../assets/img/services/tool-postgre.png')}
                        alt="PostgreSQL"
                      />
                      <p className="text-sm text-agray-500 text-center group-hover:text-ablue-600">
                        PostgreSQL
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              <p className="pt-6 mt-4 text-sm text-agray-500  mx-auto text-center">
                Angular, PHP, Bootstrap, Django, Sqlite, HTML,
                CSS3, Redis, MySQL, MongoDB, Jquery... {t('and-more')}. 
              </p>
            </div>
          </div>
          {qParam === 'landing' && <FAQs></FAQs>}
          <WeHaveItAll></WeHaveItAll>
        </main>
      </div>
      {qParam === 'landing' && <WhyChooseUs></WhyChooseUs>}
      {qParam === 'landing' ? <ContactUs></ContactUs> : <Ready></Ready>}
    </Fragment>
  );
}
