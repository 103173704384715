import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Ready from "./Ready";
import { useTranslation } from "react-i18next";

export default function AboutUs(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <Fragment>
      <Helmet>
        <title>{t("about")} | Alluxi </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("about-desc")} />
        <meta property="description" content={t("about-desc")} />
        <meta property="og:title" content={t("about")} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={require("../assets/img/AboutUs/hero-about.png")}
        />
        <meta property="og:description" content={t("about-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="pt-28">
        <div className="bg-agray-100">
          <div className="px-6 md:px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto md:grid md:grid-cols-2 md:gap-6">
            <img
              className="w-full h-full object-cover order-2"
              src={require("../assets/img/AboutUs/hero-about.png")}
              alt="about-us"
            />
            <div className="text-left m-auto">
              <div className="px-4">
                <div className="pb-4">
                  <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                    {t("about")}
                  </span>
                </div>
                <h1 className="text-3xl md:text-4xl xl:text-5xl text-nblue-900 font-bold">
                  {t("about-title")}
                </h1>
                <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                  {t("about-desc")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24 px-6 md:px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
          <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense gap-4">
            <div className="mx-auto order-2">
              <div>
                <div className="mt-6">
                  <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                    {t("commitment")}
                  </span>
                  <h2 className="text-3xl md:text-4xl xl:text-5xl text-nblue-900 font-bold">
                    {t("our-mission")}
                  </h2>
                  <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                    {t("our-mission-desc")}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="w-full h-full">
                <img
                  className=""
                  src={require("../assets/img/AboutUs/mission.png")}
                  alt="mission"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-32 pt-16 m-auto bg-agray-100">
          <div className="px-6 md:px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto md:grid">
            <div className="m-auto mt-16 pt-16 text-left w-full">
              <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t("solutions")}
              </span>
              <h2 className="text-3xl md:text-4xl xl:text-5xl text-nblue-900 font-bold">
                {t("our-values")}
              </h2>
            </div>
            <div className="relative">
              <div className="mx-auto md:grid md:grid-cols-3 lg:gap-8">
                <div className="lg:py-16 w-full">
                  <div className="h-12 w-12">
                    <img
                      className="w-fit h-full"
                      src={require("../assets/img/AboutUs/ic-val-passion.png")}
                      alt="passion"
                    />
                  </div>
                  <div className="mt-6">
                    <h3 className="text-2xl text-nblue-900 font-bold">
                      {t("passion")}
                    </h3>
                    <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                      {t("passion-desc")}
                    </p>
                  </div>
                </div>
                <div className="lg:py-16 w-full">
                  <div className="h-12 w-12">
                    <img
                      className="w-fit h-full"
                      src={require("../assets/img/AboutUs/ic-val-team.png")}
                      alt="teamwork"
                    />
                  </div>
                  <div className="mt-6">
                    <h3 className="text-2xl text-nblue-900 font-bold">
                      {t("teamwork")}
                    </h3>
                    <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                      {t("teamwork-desc")}
                    </p>
                  </div>
                </div>
                <div className="lg:py-16 w-full">
                  <div className="h-12 w-12">
                    <img
                      className="w-fit h-full"
                      src={require("../assets/img/AboutUs/ic-val-respons.png")}
                      alt="responsibility"
                    />
                  </div>
                  <div className="mt-6">
                    <h3 className="text-2xl text-nblue-900 font-bold">
                      {t("responsibility")}
                    </h3>
                    <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                      {t("responsibility-desc")}
                    </p>
                  </div>
                </div>
                <div className="lg:py-16 w-full">
                  <div className="h-12 w-12">
                    <img
                      className="w-fit h-full"
                      src={require("../assets/img/AboutUs/ic-val.png")}
                      alt="accountability"
                    />
                  </div>
                  <div className="mt-6">
                    <h3 className="text-2xl text-nblue-900 font-bold">
                      {t("accountability")}
                    </h3>
                    <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                      {t("accountability-desc")}
                    </p>
                  </div>
                </div>
                <div className="lg:py-16 w-full">
                  <div className="h-12 w-12">
                    <img
                      className="w-fit h-full"
                      src={require("../assets/img/AboutUs/ic-val-ally.png")}
                      alt="ally"
                    />
                  </div>
                  <div className="mt-6">
                    <h3 className="text-2xl text-nblue-900 font-bold">
                      {t("ally")}
                    </h3>
                    <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                      {t("ally-desc")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ready></Ready>
      </div>
    </Fragment>
  );
}
